import {
  IconArrowBack,
  Inline,
  Stack,
  Text,
  TreatmentTypeBadge,
} from '@treatwell/ui';
import React, { useContext } from 'react';
import { HighlightedText } from '../HighlightedText';
import { Context } from '../LocaleWrapper';
import styles from './NearMeFooter.module.css';
import { trackCitySelection, trackTreatmentSelection } from './tracking';

export const NearMeFooter = () => {
  const { pageData, i18n } = useContext(Context);

  return (
    <>
      <Text className={styles.title} as="h2" type="lgHeader">
        <HighlightedText
          className={styles.highlight}
          text={i18n('page.home.near-me.title')}
        />
      </Text>
      <div className={styles.grid}>
        {pageData.homepage.nearMeFooter.categories.map(category => {
          const treatmentBadge: {
            category: 'treatment' | 'venue';
            id: number;
          } = {
            category: 'treatment',
            id: Number(category.treatmentCategoryGroupId),
          };

          if (category.venueTypeId) {
            treatmentBadge.category = 'venue';
            treatmentBadge.id = Number(category.venueTypeId);
          }

          return (
            <Stack key={category.name} space="lg">
              <a
                href={category.url}
                className={styles.treatment}
                onClick={event => {
                  event.preventDefault();

                  trackTreatmentSelection(category.name).finally(() => {
                    window.location.href = category.url;
                  });
                }}
                data-cy="near-me-treatment"
              >
                <Inline splitAfter={1} space="xs">
                  <TreatmentTypeBadge
                    size="lg"
                    colorPair={{
                      backgroundColor: 'transparent',
                      color: 'inherit',
                    }}
                    id={treatmentBadge.id}
                    category={treatmentBadge.category}
                  />
                  <Text type="bodyHeavy">{category.header}</Text>
                  <IconArrowBack className={styles.arrow} size={24} />
                </Inline>
              </a>
              <Stack className={styles.cities} space="md">
                {category.cities.map(city => {
                  return (
                    <Text key={city.url}>
                      <a
                        href={city.url}
                        className={styles.city}
                        onClick={event => {
                          event.preventDefault();

                          trackCitySelection(city.name).finally(() => {
                            window.location.href = city.url;
                          });
                        }}
                        data-cy="near-me-city"
                      >
                        {city.name}
                      </a>
                    </Text>
                  );
                })}
              </Stack>
            </Stack>
          );
        })}
      </div>
    </>
  );
};
