import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

function trackHomePageEvent(
  category: string,
  action: string,
  property?: string,
  label?: string,
  value?: string | number
): Promise<void> {
  return trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export const TREATMENT_TAB = 'treatment_search_form';
export const VENUE_TAB = 'venue_search_form';
export const SEARCH_BUTTON = 'search_button';

const ACTIONS = {
  focus: 'focus',
  blur: 'blur',
  type: 'type',
  select: 'select',
  clear: 'clear',
  click: 'click',
  load: 'load',
};

export function trackDefaultSearchExperimentLoaded(variant: string): void {
  trackHomePageEvent('homepage', ACTIONS.load, undefined, variant);
}

// Search Box
export function tabSelect(tab: number): void {
  const searchBoxTabs = [TREATMENT_TAB, VENUE_TAB];
  trackHomePageEvent(searchBoxTabs[tab], ACTIONS.select, 'tab');
}

export function searchButton(tab: string, status: string): Promise<void> {
  return trackHomePageEvent(tab, ACTIONS.select, SEARCH_BUTTON, status);
}

export function fieldFocus(tab: string, field: string): void {
  trackHomePageEvent(tab, ACTIONS.focus, field);
}

export function fieldBlur(tab: string, field: string): void {
  trackHomePageEvent(tab, ACTIONS.blur, field);
}

export function fieldSelectWithPosition(
  tab: string,
  field: string,
  label: string,
  treatmentPosition?: number
): void {
  trackHomePageEvent(
    tab,
    `${ACTIONS.select}_${treatmentPosition}`,
    field,
    label
  );
}

export function fieldSelect(tab: string, field: string, label: string): void {
  trackHomePageEvent(tab, ACTIONS.select, field, label);
}

export function fieldClear(tab: string, field: string): void {
  trackHomePageEvent(tab, ACTIONS.clear, field);
}

export function fieldType(
  tab: string,
  field: string,
  inputValue?: string,
  resultCount?: number
): void {
  trackHomePageEvent(tab, ACTIONS.type, field, inputValue, resultCount);
}

export function externalLocationSearchSelect(
  category: string,
  value: string
): void {
  trackStructuredEvent({
    category,
    property: 'location_field',
    action: 'external_location_search',
    label: value,
  });
}

// Merchandising Slot
export function trackMerchandisingSlotSelect(url: string): void {
  trackHomePageEvent('homepage_merch_slots', ACTIONS.click, undefined, url);
}
