import { addDays } from 'date-fns';

export function hasTwelveHourClock(countryCode: string): boolean {
  const countriesWithTwelveHourClock = [
    'US',
    'GB',
    'PH',
    'CA',
    'AU',
    'NZ',
    'IN',
    'EG',
    'SA',
    'CO',
    'PK',
    'MY',
  ];

  return countriesWithTwelveHourClock.some(
    (item: string) => countryCode.toUpperCase() === item.toUpperCase()
  );
}

export function convertToTwelveHourClockFragments(
  time: string
): [string, string] {
  // NOTE: expects "time" in format HH:MM - which must be valid 24 clock
  const [hoursString, minutes] = time.split(':');
  const hours = parseInt(hoursString, 10);
  const isAfterTwelve = hours >= 12 && hours !== 24;
  const meridiem = isAfterTwelve ? 'PM' : 'AM';
  const displayHours = isAfterTwelve || hours === 24 ? hours - 12 : hours;
  const timeStr = `${displayHours === 0 ? '12' : displayHours}:${minutes}`;

  return [timeStr, meridiem];
}

export function convertToTwelveHourClock(time: string): string {
  const [timeStr, meridiem] = convertToTwelveHourClockFragments(time);
  return `${timeStr} ${meridiem}`;
}

export function dayHourList(
  useTwelveHourClock: boolean,
  start: number,
  end: number = start
): { value: string; label: string }[] {
  // "start" and "end" number must be within 0 - 23 range, start must be smaller than end
  const optionValues = [];

  for (let hour = start; hour <= end; hour++) {
    const hourStr: string = hour < 10 ? `0${hour.toString()}` : hour.toString();
    const value = `${hourStr}00`;

    const label = useTwelveHourClock
      ? convertToTwelveHourClock(`${hourStr}:00`)
      : `${hourStr}:00`;
    optionValues.push({ value, label });
  }
  return optionValues;
}

export function changeTimeHour(time: string, adjustment: number): string {
  // time as 24 hour format time (example: 1400)
  // adjustment takes int number how many hours different (example: -3, 2)
  let timeHours = parseInt(time.substring(0, 2), 10) + adjustment;
  if (timeHours < 0) {
    timeHours += 24;
  }
  if (timeHours > 24) {
    timeHours %= 24;
  }
  const timeMinutes = time.substring(2, 4);
  const nextHour =
    timeHours < 10 ? `0${timeHours.toString()}` : timeHours.toString();
  return nextHour + timeMinutes;
}

export function deltaTimeMinutes(a: string, b: string): number {
  // expected a & b format: HHMM
  return minutesFromMidnight(a) - minutesFromMidnight(b);
}

function splitTimeString(timeStr: string): { hours: number; minutes: number } {
  // expected timeStr format: HHMM
  return {
    hours: parseInt(timeStr.substring(0, 2), 10),
    minutes: parseInt(timeStr.substring(2, 4), 10),
  };
}

function minutesFromMidnight(timeStr: string): number {
  // expected timeStr format: HHMM
  const time = splitTimeString(timeStr);
  return time.hours * 60 + time.minutes;
}

/**
 * Examples:
 * - ['23:00', undefined]
 * - ['11:00', 'PM']
 */
export type TimeLabel = [string, string | undefined];

export function localizedTime(time: string, country: string): TimeLabel {
  if (hasTwelveHourClock(country)) {
    return convertToTwelveHourClockFragments(time);
  }
  // adjusting 7:00 as 07:00 for 24 hour format
  const formattedTime = time && time.length < 5 ? `0${time}` : time;
  return [formattedTime, undefined];
}

export function convertHoursToString(hour: number): string {
  const converted = `${hour}00`;
  return converted && converted.length < 4 ? `0${converted}` : converted;
}

export function dateIn7Days() {
  const today = new Date();
  return addDays(today, 6);
}

export function dateIn3Days() {
  const today = new Date();
  return addDays(today, 2);
}
