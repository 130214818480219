import { fetchHTTP, RequestData } from 'js/helpers/service';
import { generateRuid } from 'js/helpers/ruid';
import { BrowsePageOutput } from 'js/model/rainbow/browse-page/BrowsePageOutput';
import { VenueListingCriteria } from 'js/model/rainbow/browse-page/VenueListingCriteria';
import { parseJson } from 'js/helpers/parse-json';
import { stringify } from 'qs';
import { VenueMenuItemType } from 'js/model/rainbow/VenueMenuItemType';

export async function fetchBrowse(
  state: RequestData,
  browsePath: string,
  ruid: string = generateRuid(),
  parameters: VenueListingCriteria = {}
): Promise<BrowsePageOutput> {
  const parametersWithUri = {
    ...parameters,
    currentBrowseUri: decodeURIComponent(browsePath),
  };

  const response: Response = await fetchHTTP(
    state,
    `/api/v1/page/browse?${stringify(parametersWithUri, {
      allowDots: true,
      arrayFormat: 'comma',
    })}`,
    ruid,
    true
  );
  const browsePageOutput = parseJson<BrowsePageOutput>(
    await response.text(),
    ruid
  );

  if (!browsePageOutput) {
    throw new Error('Error occurred fetching Browse data');
  }

  // Remove service option groups. They're not currently used by browse page,
  // and would add significantly to the state that is marshalled and sent to
  // the browser.
  browsePageOutput.results.forEach(result => {
    result.data.menuHighlights.forEach(service => {
      if (
        service.type === VenueMenuItemType.Treatment ||
        service.type === VenueMenuItemType.TreatmentPackage
      ) {
        // eslint-disable-next-line no-param-reassign
        service.data.optionGroups = [];
      }
    });
  });

  // Check if the brands that the user has selected are included in the list of brands
  // for the given location. Depending on how many other filters have been used some brands
  // may not be available anymore
  const updatedSpecificationProductBrands = browsePageOutput.specification.productBrands?.filter(
    productBrand => {
      return browsePageOutput.aggregations.brands?.some(brand => {
        return productBrand.id === brand.id;
      });
    }
  );

  if (updatedSpecificationProductBrands) {
    browsePageOutput.specification.productBrands = updatedSpecificationProductBrands;
  }
  return browsePageOutput;
}
