import { mParticleLogEvent, mParticleEventType } from '@treatwell/ui';
import { Location } from 'js/model/rainbow/browse-page/VenueListingSpecificationOutput';
import { BrowsePageOutput } from 'js/model/rainbow/browse-page/BrowsePageOutput';

type TreatmentSearchProps = {
  country: string;
  platform: string;
  ['search_results_number']: number;
  ['search_type']: string;
  ['treatment_type_id']?: string;
  ['treatment_id']?: string;
  ['venue_type_id']?: string;
  ['location_type']?: string;
  ['location_tree_id']?: string;
  ['location_latitude']?: number;
  ['location_longitude']?: number;
  ['location_name']?: string;
  ['location_external_id']?: string;
  ['location_zip_code']?: string;
  ['location_zip_code_id']?: string;
  ['location_box_northeast_lat']?: number;
  ['location_box_northeast_lon']?: number;
  ['location_box_southwest_lat']?: number;
  ['location_box_southwest_lon']?: number;
  ['location_radius_distance']?: number;
  ['location_radius_distance_unit']?: string;
  ['selected_appointment_date_type']?: string;
  ['selected_appointment_date_from']?: string;
  ['selected_appointment_date_to']?: string;
  ['selected_appointment_time_type']?: string;
  ['selected_appointment_time_from']?: number;
  ['selected_appointment_time_to']?: number;
  ['sorted_by']?: string;
  ['price_filter_max']?: number;
  ['price_filter_min']?: number;
  ['venue_amenities_id_filter']?: string;
  ['brands_id_filter']?: string;
  ['brands_filter']: string;
  ['accolade_filter_id']?: string;
  ['is_new_venues_filtered']: boolean;
  ['is_discount_filtered']: boolean;
  ['is_express_treatments_filtered']: boolean;
  ['treatment_alias_id']?: string;
};

export const getLocationInfo = (location?: Location) => {
  if (location?.point) {
    return {
      locationType: 'lat_lon',
      locationLatitude: location.point.lat,
      locationLongitude: location.point.lon,
      locationDistanceUnit: location.radius?.distanceUnit,
      locationRadiusDistance: location.radius?.distance,
    };
  }

  if (location?.external) {
    return {
      locationType: 'external',
      locationLatitude: location.external.point.lat,
      locationLongitude: location.external.point.lon,
      locationDistanceUnit: location.radius?.distanceUnit,
      locationRadiusDistance: location.radius?.distance,
    };
  }

  if (location?.tree) {
    return {
      locationType: location.tree.type,
      locationLatitude: location.tree.point.lat,
      locationLongitude: location.tree.point.lon,
      locationDistanceUnit: location.radius?.distanceUnit,
      locationRadiusDistance: location.radius?.distance,
      locationName: location.tree.name,
    };
  }

  if (location?.postalReference) {
    return {
      locationType: 'postal_reference',
      locationLatitude: location.postalReference.point.lat,
      locationLongitude: location.postalReference.point.lon,
      locationName: location.postalReference.name,
      locationDistanceUnit: location.radius?.distanceUnit,
      locationRadiusDistance: location.radius?.distance,
    };
  }
  if (location?.box) {
    return {
      location_box_northeast_lat: location.box.northEastLat,
      location_box_northeast_lon: location.box.northEastLon,
      location_box_southwest_lat: location.box.southWestLat,
      location_box_southwest_lon: location.box.southWestLon,
    };
  }
};

export const trackSearch = async (
  countryCode: string,
  browsePageOutput: BrowsePageOutput
) => {
  const browsePageOutputSpecification = browsePageOutput.specification;
  const location = browsePageOutputSpecification.location;
  let searchType = 'treatment_type';
  if (browsePageOutputSpecification.venueType?.id) {
    searchType = 'venue_type';
  }
  if (browsePageOutputSpecification.treatmentCategories) {
    searchType = 'treatment';
  }

  await mParticleLogEvent<TreatmentSearchProps>(
    'treatment_searched',
    mParticleEventType.Search,
    {
      country: countryCode,
      platform: 'web',
      search_results_number: browsePageOutput.results.length,
      search_type: searchType,
      treatment_type_id: browsePageOutputSpecification.treatmentCategoryGroup?.id.toString(),
      treatment_id: browsePageOutputSpecification.treatmentCategories
        ?.map(treatment => treatment.id)
        .join(', '),
      venue_type_id: browsePageOutputSpecification.venueType?.id.toString(),
      location_type: getLocationInfo(location)?.locationType,
      location_tree_id: browsePageOutputSpecification.location?.tree?.id.toString(),
      location_latitude: getLocationInfo(location)?.locationLatitude,
      location_longitude: getLocationInfo(location)?.locationLongitude,
      location_name: getLocationInfo(location)?.locationName,
      location_external_id: browsePageOutputSpecification.location?.external?.id.toString(),
      location_zip_code:
        browsePageOutputSpecification.location?.postalReference?.name,
      location_zip_code_id: browsePageOutputSpecification.location?.postalReference?.id.toString(),
      location_box_northeast_lat: getLocationInfo(location)
        ?.location_box_northeast_lat,
      location_box_northeast_lon: getLocationInfo(location)
        ?.location_box_northeast_lon,
      location_box_southwest_lat: getLocationInfo(location)
        ?.location_box_southwest_lat,
      location_box_southwest_lon: getLocationInfo(location)
        ?.location_box_southwest_lon,
      location_radius_distance: getLocationInfo(location)
        ?.locationRadiusDistance,
      location_radius_distance_unit: getLocationInfo(location)
        ?.locationDistanceUnit,
      selected_appointment_date_type: browsePageOutputSpecification.date?.from
        ? 'selected_date'
        : 'any_date',
      selected_appointment_date_from: browsePageOutputSpecification.date?.from,
      selected_appointment_date_to: browsePageOutputSpecification.date?.to,
      selected_appointment_time_type: browsePageOutputSpecification.startHours
        ? 'selected_time'
        : 'any_time',
      selected_appointment_time_from: browsePageOutputSpecification.startHours,
      selected_appointment_time_to: browsePageOutputSpecification.endHours,
      sorted_by: browsePageOutputSpecification.sort,
      price_filter_max: browsePageOutputSpecification.price?.max,
      price_filter_min: browsePageOutputSpecification.price?.min,
      venue_amenities_id_filter: browsePageOutputSpecification.amenities
        ?.map(amenity => amenity.id)
        .join(', '),
      brands_id_filter: browsePageOutputSpecification.productBrands?.length
        ? browsePageOutputSpecification.productBrands[0].id.toString()
        : undefined, // only one brand can be searched for at a time
      brands_filter: browsePageOutputSpecification.productBrands?.length
        ? browsePageOutputSpecification.productBrands[0].name
        : 'all_brands',
      accolade_filter_id: browsePageOutputSpecification.accolade?.id.toString(),
      is_new_venues_filtered: Boolean(browsePageOutputSpecification.newVenues),
      is_discount_filtered: Boolean(
        browsePageOutputSpecification.yieldDiscounted
      ),
      is_express_treatments_filtered: Boolean(
        browsePageOutputSpecification.maxServiceDurationMinutes
      ),
      treatment_alias_id: browsePageOutput.aliasId?.toString(),
    }
  );
};
