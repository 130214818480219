import * as React from 'react';
import clsx from 'clsx';

import startOfToday from 'date-fns/startOfToday';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { scrollElementTo } from 'js/helpers/animations';
import { dayHourList, deltaTimeMinutes, changeTimeHour } from 'js/helpers/time';
import { Button } from 'js/components/Button';
import { ButtonColour } from 'js/components/Button/ButtonColour';
import { ArrowPosition } from 'js/components/ButtonArrow/ArrowPosition';
import { DatepickerInRange } from 'js/components/controls/DatepickerInRange';
import { NativeSelectInput } from 'js/components/controls/NativeSelectInput';
import { usePrevious } from 'js/hooks/usePrevious';
import { CmsCommonControls } from 'js/model/cms/cms-common-controls';
import { CmsCalendar } from 'js/model/cms/cms-calendar';
import { isIE11 } from 'js/helpers/is-ie11';
import addDays from 'date-fns/addDays';
import { TimeIcon } from './TimeIcon';
import { DateIcon } from './DateIcon';
import { DateTimeOptions } from './DateTimeOptions';
import { ButtonGroup, ButtonData } from './ButtonGroup';
import { DateTimeButtonKeys } from './DateTimeButtonKeys';
import styles from './DateTimeDialog.module.css';
import { TrackingDetails } from './TrackingDetails';

interface Props {
  cmsCalendar: CmsCalendar;
  cmsCommonControls: CmsCommonControls;
  activeTimeButton: string;
  activeDateButton: string;
  selectableToEndOfMonth: boolean;
  maxDaysInFuture: number;
  selectedDate?: Date;
  selectedTimeFrom: string;
  selectedTimeTo: string;
  showDatepicker: boolean;
  timeButtons: ButtonData[];
  dateButtons: ButtonData[];
  isDesktop?: boolean;

  onDateSelect: (date: Date | null) => void;
  onDatepickerToggle: (showDatepicker: boolean) => void;
  onDateButtonSelect: (buttonKey: string) => void;
  onTimeSelect: (timeFrom: string, timeTo: string) => void;
  onTimeButtonSelect: (buttonKey: string) => void;
  onDone: () => void; // TODO definition incorrect
  onTrack: (details: TrackingDetails) => void;
  changeDefaultSearchExperiment?: string;
}

export function DateTimeDialog({
  isDesktop = false,
  ...props
}: Props): React.ReactElement {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const prevActiveTimeButton = usePrevious(props.activeTimeButton);

  React.useEffect(() => {
    if (isShowTimeFromTo() && prevActiveTimeButton !== props.activeTimeButton) {
      if (containerRef.current !== null) {
        scrollElementTo(
          containerRef.current,
          0,
          containerRef.current.scrollHeight
        );
      }
    }
  }, [prevActiveTimeButton, props.activeTimeButton]);

  function onDateSelect(dateString: string, date: Date): void {
    if (props.onDateSelect) {
      props.onDateSelect(date);
    }

    track({
      property: 'calendar',
      action: 'select',
      label: 'day',
      value: differenceInCalendarDays(date, new Date()),
    });
  }

  function onNativeFromTimeFocus(): void {
    track({
      property: 'min_time',
      action: 'expand',
    });
  }

  function onNativeFromTimeChange(value: string): void {
    track({
      property: 'min_time',
      action: 'select',
      label: `${value.substr(0, 2)}:${value.substr(2, 4)}`,
    });

    if (!props.onTimeSelect) {
      return;
    }

    let timeTo = props.selectedTimeTo;

    if (deltaTimeMinutes(value, timeTo) >= 0) {
      timeTo = changeTimeHour(value, 1);
    }
    props.onTimeSelect(value, timeTo);
  }

  function onNativeToTimeFocus(): void {
    track({
      property: 'max_time',
      action: 'expand',
    });
  }

  function onNativeToTimeChange(value: string): void {
    track({
      property: 'max_time',
      action: 'select',
      label: `${value.substr(0, 2)}:${value.substr(2, 4)}`,
    });

    if (!props.onTimeSelect) {
      return;
    }

    let timeFrom = props.selectedTimeFrom;
    if (deltaTimeMinutes(timeFrom, value) >= 0) {
      timeFrom = changeTimeHour(value, -1);
    }

    props.onTimeSelect(timeFrom, value);
  }

  function renderDatepicker(): React.ReactNode {
    if (!props.showDatepicker) {
      return null;
    }

    return (
      <div className={styles.datepickerWrap}>
        <DatepickerInRange
          selectableToEndOfMonth={props.selectableToEndOfMonth}
          maxDaysInFuture={props.maxDaysInFuture}
          selectedDate={props.selectedDate}
          onDateSelect={onDateSelect}
          cmsCalendar={props.cmsCalendar}
        />
      </div>
    );
  }

  function renderDateOptions(): React.ReactNode {
    return (
      <DateTimeOptions
        icon={<DateIcon />}
        header={props.cmsCommonControls['datetime-input']['date-header']}
        dataCy="DateOptionsButtonGroup"
      >
        <ButtonGroup
          buttons={props.dateButtons}
          onClick={onDateButtonClick}
          arrowPosition={isDesktop ? ArrowPosition.Right : ArrowPosition.Bottom}
          activeButtonKey={props.activeDateButton}
        />
      </DateTimeOptions>
    );
  }

  function renderTimeOptions(): React.ReactNode | undefined {
    if (isIE11()) {
      // https://github.com/facebook/react/issues/11405 means that the time
      // range 'from' and 'to' buttons cannot be made to work in IE 11.
      // So don't render the time related buttons.
      //
      // This does mean that the complete button is not ideally
      // placed when the calender is open. But we can live with it
      // for just IE11.
      return;
    }

    return (
      <DateTimeOptions
        icon={<TimeIcon />}
        header={props.cmsCommonControls['datetime-input']['time-header']}
      >
        <ButtonGroup
          buttons={props.timeButtons}
          onClick={onTimeButtonClick}
          activeButtonKey={props.activeTimeButton}
        />
        {isShowTimeFromTo() && renderTimeFromTo()}
      </DateTimeOptions>
    );
  }

  function isShowTimeFromTo(): boolean {
    return props.activeTimeButton === DateTimeButtonKeys.CHOOSE_TIME;
  }

  function renderTimeFromTo(): React.ReactNode {
    const optionValuesFrom = dayHourList(false, 0, 23);
    const optionValuesTo = dayHourList(false, 1, 24);
    return (
      <>
        <div>
          <div className={styles.selectLabel}>
            {props.cmsCommonControls['datetime-input']['time-from-label']}
          </div>
          <NativeSelectInput
            optionValues={optionValuesFrom}
            selectedValue={props.selectedTimeFrom}
            onChange={onNativeFromTimeChange}
            onFocus={onNativeFromTimeFocus}
          />
        </div>
        <div>
          <div className={styles.selectLabel}>
            {props.cmsCommonControls['datetime-input']['time-to-label']}
          </div>
          <NativeSelectInput
            optionValues={optionValuesTo}
            selectedValue={props.selectedTimeTo}
            onChange={onNativeToTimeChange}
            onFocus={onNativeToTimeFocus}
          />
        </div>
      </>
    );
  }

  function onDateButtonClick(dateButton: string): void {
    if (dateButton === DateTimeButtonKeys.CHOOSE_DATE) {
      props.onDateButtonSelect(dateButton);
      props.onDatepickerToggle(true);
    } else {
      if (dateButton === DateTimeButtonKeys.NEXT_7_DAYS) {
        props.onDateButtonSelect('NEXT_7_DAYS');
        const date = addDays(new Date(), 6);
        props.onDateSelect(date);
        return;
      }
      if (dateButton === DateTimeButtonKeys.NEXT_3_DAYS) {
        props.onDateButtonSelect('NEXT_3_DAYS');
        const date = addDays(new Date(), 2);
        props.onDateSelect(date);
        return;
      }

      const date = getDateFromDateOption(dateButton);
      props.onDateButtonSelect(dateButton);
      props.onDatepickerToggle(false);

      if (props.onDateSelect) {
        props.onDateSelect(date);
      }
    }

    track({
      property: 'date_filter',
      action: 'select',
      label: props.dateButtons.find(
        (button: ButtonData) => button.key === dateButton
      )!.label, // TODO
    });
  }

  function onTimeButtonClick(timeButton: string): void {
    if (!props.onTimeButtonSelect) {
      return;
    }

    props.onTimeButtonSelect(timeButton);

    track({
      property: 'time_filter',
      action: 'select',
      label: props.timeButtons.find(
        (button: ButtonData) => button.key === timeButton
      )!.label, // TODO
    });
  }

  function track(details: TrackingDetails): void {
    if (props.onTrack) {
      props.onTrack(details);
    }
  }

  function getDateFromDateOption(option: string): Date | null {
    let date;

    switch (option) {
      case DateTimeButtonKeys.TODAY:
        date = startOfToday();
        break;
      case DateTimeButtonKeys.TOMORROW:
        date = startOfTomorrow();
        break;
      default:
        date = null;
        break;
    }
    return date;
  }

  const dialogClasses = {
    [styles.dialog]: true,
    [styles.withDatepickerDesktop]: props.showDatepicker && isDesktop,
    [styles.dialogMobile]: !isDesktop,
  };

  return (
    <div className={clsx(dialogClasses)} ref={containerRef}>
      <div className={styles.controls}>
        {renderDateOptions()}
        {!isDesktop ? renderDatepicker() : null}
        {renderTimeOptions()}
        <div className={styles.applyButtonContainer}>
          <Button
            positioningClassname={clsx(
              isDesktop ? styles.applyButtonDesktop : styles.applyButtonMobile
            )}
            label={props.cmsCommonControls['datetime-input']['complete-button']}
            colour={ButtonColour.Blue}
            onClick={props.onDone}
          />
        </div>
      </div>
      {isDesktop ? renderDatepicker() : null}
    </div>
  );
}
